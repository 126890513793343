import { CreateDialog, EditDialog } from "@react-admin/ra-form-layout";
import { Datagrid, DateField, List, ListActions, ReferenceField, TextField } from "react-admin";
import { SiteResourcesEnum } from "shared/enums/resourceMappings";
import RteCommunicationMarketConfigCreateUpdate from "./RteCommunicationMarketConfigCreateUpdate";

const RteCommunicationMarketConfigList = () => {
  return (
    <>
      <List actions={<ListActions hasCreate />}>
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <ReferenceField source="edr_id" reference={SiteResourcesEnum.Groups} />
          <TextField source="market" />
          <TextField source="status" />
          <DateField showTime source="created_at" locales={"FR-fr"} />
          <DateField showTime source="updated_at" locales={"FR-fr"} />
        </Datagrid>
      </List>
      <CreateDialog fullWidth maxWidth="sm">
        <RteCommunicationMarketConfigCreateUpdate />
      </CreateDialog>
      <EditDialog fullWidth maxWidth="sm">
        <RteCommunicationMarketConfigCreateUpdate />
      </EditDialog>
    </>
  );
};

export default RteCommunicationMarketConfigList;
