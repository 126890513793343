import { CreateDialog } from "@react-admin/ra-form-layout";
import { Datagrid, DateField, List, ListActions, ReferenceField, TextField } from "react-admin";
import { SiteResourcesEnum } from "shared/enums/resourceMappings";
import RteCommunicationTestConfigCreate from "./RteCommunicationTestConfigCreate";

const RteCommunicationTestConfigList = () => {
  return (
    <>
      <List actions={<ListActions hasCreate />}>
        <Datagrid>
          <TextField source="id" />
          <DateField showTime source="start_date" locales={"FR-fr"} />
          <DateField showTime source="end_date" locales={"FR-fr"} />
          <TextField source="type" />
          <ReferenceField source="group_id" reference={SiteResourcesEnum.Groups} />
          <DateField showTime source="created_at" locales={"FR-fr"} />
          <DateField showTime source="updated_at" locales={"FR-fr"} />
        </Datagrid>
      </List>
      <CreateDialog fullWidth maxWidth="sm">
        <RteCommunicationTestConfigCreate />
      </CreateDialog>
    </>
  );
};

export default RteCommunicationTestConfigList;
