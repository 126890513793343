import { NumberInput, ReferenceInput, required, SimpleForm } from "react-admin";
import CustomDateTimeInput from "shared/components/inputs/CustomDateTimeInput";
import { FranceGroupTypeEnum } from "shared/enums/globalEnums";
import { SiteResourcesEnum } from "shared/enums/resourceMappings";
import { validateEndDateAfterStartDate } from "shared/validators/dates";

const RteCommunicationFakePaCreate = () => {
  return (
    <SimpleForm>
      <CustomDateTimeInput source="start_date" validate={[required(), validateEndDateAfterStartDate]} />
      <CustomDateTimeInput source="end_date" validate={[required(), validateEndDateAfterStartDate]} />
      <ReferenceInput
        source="edp_id"
        reference={SiteResourcesEnum.Sites}
        filter={{
          type: FranceGroupTypeEnum.rte_edp,
        }}
      />
      <NumberInput source="r1_h" />
      <NumberInput source="r1_b" />
      <NumberInput source="r2_h" />
      <NumberInput source="r2_b" />
      <NumberInput source="p0" />
    </SimpleForm>
  );
};

export default RteCommunicationFakePaCreate;
