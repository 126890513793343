import { AutocompleteInput, NumberInput, ReferenceInput, required, SelectInput, SimpleForm } from "react-admin";
import { FranceGroupTypeEnum } from "shared/enums/globalEnums";
import { packetTypeChoices, tiTypeChoices } from "shared/enums/reactAdminChoices";
import { SiteResourcesEnum } from "shared/enums/resourceMappings";

const RteCommunicationConfigCreate = () => {
  return (
    <SimpleForm>
      <NumberInput source="ioa" validate={[required()]} />
      <ReferenceInput
        source="code_tm"
        reference={SiteResourcesEnum.Groups}
        filter={{
          type: FranceGroupTypeEnum.rte_edr,
        }}
      >
        <AutocompleteInput optionValue="code_tm" validate={[required()]} />
      </ReferenceInput>
      <SelectInput source="packet_type" choices={packetTypeChoices} validate={[required()]} />
      <SelectInput source="telemetry_field_name" choices={tiTypeChoices} validate={[required()]} />
    </SimpleForm>
  );
};

export default RteCommunicationConfigCreate;
