import { AutocompleteInput, ReferenceInput, required, SelectInput, SimpleForm } from "react-admin";
import { FranceGroupTypeEnum } from "shared/enums/globalEnums";
import { rteCommunicationMarketStatusChoices, rteCommunicationMarketTypeChoices } from "shared/enums/reactAdminChoices";
import { SiteResourcesEnum } from "shared/enums/resourceMappings";

const RteCommunicationMarketConfigCreateUpdate = () => {
  return (
    <SimpleForm>
      <ReferenceInput
        source="edr_id"
        reference={SiteResourcesEnum.Groups}
        filter={{
          type: FranceGroupTypeEnum.rte_edr,
        }}
      >
        <AutocompleteInput validate={[required()]} />
      </ReferenceInput>
      <SelectInput source="market" choices={rteCommunicationMarketTypeChoices} validate={[required()]} />
      <SelectInput source="status" choices={rteCommunicationMarketStatusChoices} validate={[required()]} />
    </SimpleForm>
  );
};

export default RteCommunicationMarketConfigCreateUpdate;
