import { CreateDialog } from "@react-admin/ra-form-layout";
import { Datagrid, DateField, List, ListActions, TextField } from "react-admin";
import RteCommunicationConfigCreate from "./RteCommunicationConfigCreate";

const RteCommunicationConfigList = () => {
  return (
    <>
      <List actions={<ListActions hasCreate />}>
        <Datagrid>
          <TextField source="id" />
          <TextField source="ioa" />
          <TextField source="code_tm" />
          <TextField source="packet_type" />
          <TextField source="telemetry_field_name" />
          <DateField showTime source="created_at" locales={"FR-fr"} />
          <DateField showTime source="updated_at" locales={"FR-fr"} />
        </Datagrid>
      </List>
      <CreateDialog fullWidth maxWidth="sm">
        <RteCommunicationConfigCreate />
      </CreateDialog>
    </>
  );
};

export default RteCommunicationConfigList;
