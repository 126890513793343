import { AutocompleteInput, ReferenceInput, required, SelectInput, SimpleForm } from "react-admin";
import CustomDateTimeInput from "shared/components/inputs/CustomDateTimeInput";
import { FranceGroupTypeEnum } from "shared/enums/globalEnums";
import { rteCommunicationTestConfigTypeChoices } from "shared/enums/reactAdminChoices";
import { SiteResourcesEnum } from "shared/enums/resourceMappings";
import { validateEndDateAfterStartDate } from "shared/validators/dates";

const RteCommunicationTestConfigCreate = () => {
  return (
    <SimpleForm>
      <CustomDateTimeInput source="start_date" validate={[required(), validateEndDateAfterStartDate]} />
      <CustomDateTimeInput source="end_date" validate={[required(), validateEndDateAfterStartDate]} />
      <SelectInput source="market" choices={rteCommunicationTestConfigTypeChoices} validate={[required()]} />

      <ReferenceInput
        source="edr_id"
        reference={SiteResourcesEnum.Groups}
        filter={{
          type: FranceGroupTypeEnum.rte_edr,
        }}
      >
        <AutocompleteInput validate={[required()]} />
      </ReferenceInput>
    </SimpleForm>
  );
};

export default RteCommunicationTestConfigCreate;
