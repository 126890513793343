// validators.ts
import { Validator } from "react-admin";

/**
 * Validates that the end date is strictly after the start date.
 *
 * @param value - The value of the field being validated (end_date).
 * @param allValues - All form values; expected to include a start_date field.
 * @returns A string error message if invalid, or undefined if valid.
 */
export const validateEndDateAfterStartDate: Validator = (
  value: any,
  allValues: Record<string, any> = {},
): string | undefined => {
  // Only validate if both dates are provided
  if (!value || !allValues.start_date) {
    return undefined;
  }

  const start = new Date(allValues.start_date);
  const end = new Date(value);

  if (end <= start) {
    return "End date must be after start date";
  }

  return undefined;
};
