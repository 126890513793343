import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import BuildIcon from "@mui/icons-material/Build";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import EngineeringIcon from "@mui/icons-material/Engineering";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import GroupsIcon from "@mui/icons-material/Groups";
import HubIcon from "@mui/icons-material/Hub";
import NetworkCheckIcon from "@mui/icons-material/NetworkCheck";
import PetsIcon from "@mui/icons-material/Pets";
import PsychologyIcon from "@mui/icons-material/Psychology";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import SignalCellularNoSimIcon from "@mui/icons-material/SignalCellularNoSim";
import SpeedIcon from "@mui/icons-material/Speed";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import UpdateIcon from "@mui/icons-material/Update";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import FinlandFlagEmojiIcon from "shared/components/icons/flags/FinlandFlagEmojiIcon";
import FrenchFlagEmojiIcon from "shared/components/icons/flags/FrenchFlagEmojiIcon";
import LeakAddIcon from "@mui/icons-material/LeakAdd";
import {
  AfrrEngagementResourcesEnum,
  EcpResourcesEnum,
  MaintenanceResourcesEnum,
  MecapaResourcesEnum,
  OptimizerResourcesEnum,
  PlanningResourcesEnum,
  RegelleistungResourcesEnum,
  RteCommunicationResourcesEnum,
  RteCommunicationV2ResourcesEnum,
  SiteResourcesEnum,
  TestsRteResourcesEnum,
  TopNiveauResourcesEnum,
  UserResourcesEnum,
} from "shared/enums/resourceMappings";

interface MenuItem {
  label: string;
  icon?: JSX.Element;
  path?: string;
  listItems?: MenuItem[];
}

export const menuItems: MenuItem[] = [
  {
    label: "Jbox",
    icon: <BatteryChargingFullIcon />,
    listItems: [
      { label: "Sites", path: SiteResourcesEnum.Sites, icon: <FmdGoodIcon /> },
      { label: "Groups", path: SiteResourcesEnum.Groups, icon: <GroupsIcon /> },
    ],
  },
  {
    label: "Fleet Status",
    icon: <DeviceThermostatIcon />,

    listItems: [
      {
        label: "Maintenances",
        path: MaintenanceResourcesEnum.Maintenance,
        icon: <EngineeringIcon />,
      },
      { label: "Statuses", path: SiteResourcesEnum.Statuses, icon: <NetworkCheckIcon /> },
      // { label: "Statuses V2", path: SiteResourcesEnum.StatusesV2, icon: <NetworkCheckIcon /> },
    ],
  },
  {
    label: "Plannification",
    icon: <DateRangeIcon />,
    listItems: [
      {
        label: "Plannings",
        path: PlanningResourcesEnum.Plannings,
        icon: <CalendarMonthIcon />,
      },
      {
        label: "Planning Masks",
        path: PlanningResourcesEnum.PlanningMasks,
        icon: <UpdateIcon />,
      },
    ],
  },
  {
    label: "Performance Tests",
    icon: <SpeedIcon />,
    listItems: [
      { label: "Report Generation", path: SiteResourcesEnum.PerformanceChronicleGeneration, icon: <EngineeringIcon /> },
      { label: "Config Validation", path: SiteResourcesEnum.PerformanceChronicles, icon: <AlignVerticalBottomIcon /> },
    ],
  },

  {
    label: "France",
    icon: <FrenchFlagEmojiIcon />,
    listItems: [
      {
        label: "Regelleistung",
        icon: <RequestQuoteIcon />,
        listItems: [{ label: "Bids", path: RegelleistungResourcesEnum.RegelleistungBids, icon: <ReceiptIcon /> }],
      },
      {
        label: "RACOON",
        icon: <PetsIcon />,
        listItems: [
          {
            label: "Visualization Charts",
            path: AfrrEngagementResourcesEnum.Engagement,
            icon: <AssignmentTurnedInIcon />,
          },
          {
            label: "Bids",
            path: AfrrEngagementResourcesEnum.Point,
            icon: <RadioButtonCheckedIcon />,
          },
        ],
      },
      {
        label: "TOPNIVEAU",
        icon: <StarBorderIcon />,
        listItems: [
          {
            label: "Bids",
            path: TopNiveauResourcesEnum.TopNiveauBids,
            icon: <ReceiptIcon />,
          },
        ],
      },

      {
        label: "Tests TSO",
        icon: <BuildIcon />,
        listItems: [
          {
            label: "IEC Fake PAs",
            path: RteCommunicationResourcesEnum.RteCommunicationFakePas,
            icon: <ReceiptLongIcon />,
          },
          {
            label: "IEC Loss N level",
            path: RteCommunicationResourcesEnum.RteCommunicationAfrrTestConfig,
            icon: <SignalCellularNoSimIcon />,
          },
          { label: "Tests AFRR RTE", path: TestsRteResourcesEnum.TestsAfrrRte, icon: <SsidChartIcon /> },
          { label: "Tests FCR RTE", path: TestsRteResourcesEnum.TestsFcrRte, icon: <SsidChartIcon /> },
        ],
      },
      {
        label: "Mecapa",
        icon: <BuildIcon />,
        listItems: [
          {
            label: "Emax J",
            path: MecapaResourcesEnum.EmaxJGates,
          },
          {
            label: "Emax H",
            path: MecapaResourcesEnum.EmaxHGates,
          },
          {
            label: "Rte Power Gates",
            path: MecapaResourcesEnum.RtePowerGates,
          },
        ],
      },
      {
        label: "RTE communication V2",
        icon: <LeakAddIcon />,
        listItems: [
          {
            label: "Configs",
            path: RteCommunicationV2ResourcesEnum.RteCommunicationConfig,
          },
          {
            label: "Fake Pas",
            path: RteCommunicationV2ResourcesEnum.RteCommunicationFakePa,
          },
          {
            label: "Market configs",
            path: RteCommunicationV2ResourcesEnum.RteCommunicationMarketConfig,
          },
          {
            label: "Test configs",
            path: RteCommunicationV2ResourcesEnum.RteCommunicationTestConfig,
          },
        ],
      },
    ],
  },

  {
    label: "Finland",
    icon: <FinlandFlagEmojiIcon />,
    listItems: [
      {
        label: "ECP",
        path: EcpResourcesEnum.EcpReservePlanPoints,
        icon: <ReceiptIcon />,
      },
    ],
  },

  {
    label: "Optimizer",
    icon: <HubIcon />,
    listItems: [
      {
        label: "Certification Tests",
        path: OptimizerResourcesEnum.OptiCertificationsTests,
        icon: <CheckCircleIcon />,
      },
      { label: "Certifications", path: OptimizerResourcesEnum.OptiCertifications, icon: <WorkspacePremiumIcon /> },
      {
        label: "Groups Certifications",
        path: OptimizerResourcesEnum.OptiGroupsCertifications,
        icon: <GroupWorkIcon />,
      },
      { label: "Opti tasks", path: OptimizerResourcesEnum.OptiTasks, icon: <PsychologyIcon /> },
    ],
  },

  {
    label: "Authentication",
    icon: <GroupsIcon />,
    listItems: [{ label: "Users", path: UserResourcesEnum.Users, icon: <AccountCircleIcon /> }],
  },
];

/**
 * Recursively traverses a list of menu items to build a mapping
 * from each leaf’s `path` to an array of its parent labels.
 *
 * @param items - The current list of menu items.
 * @param parentChain - An array of labels representing the ancestry.
 * @returns An object whose keys are paths and values are arrays of parent labels.
 */
function buildPathParentMapping(items: MenuItem[], parentChain: string[] = []): Record<string, string[]> {
  return items.reduce<Record<string, string[]>>((acc, item) => {
    // If the item has a path, record it with the current parent chain.
    if (item.path) {
      acc[item.path] = parentChain;
    }
    // If the item has nested listItems, traverse them.
    if (item.listItems) {
      // Pass down a new chain that includes this item's label.
      const childMapping = buildPathParentMapping(item.listItems, [...parentChain, item.label]);
      Object.assign(acc, childMapping);
    }
    return acc;
  }, {});
}

export const breadcrumbInfos = menuItems.reduce<Record<string, string[]>>((acc, item) => {
  if (item.listItems) {
    // For children, the parent chain starts with the top-level label.
    Object.assign(acc, buildPathParentMapping(item.listItems, [item.label]));
  } else if (item.path) {
    // If a top-level item happens to have a path.
    acc[item.path] = [];
  }
  return acc;
}, {});
