export enum SiteResourcesEnum {
  Sites = "sites",
  Statuses = "statuses",
  StatusesV2 = "statusesV2",
  Groups = "groups",
  Telemetry1S = "telemetry1S",
  NLevels = "nLevels",
  SiteConfigurations = "configurations",
  PerformanceChronicles = "performanceChronicles",
  PerformanceChronicleResults = "performanceChronicleResults",
  PerformanceChronicleGeneration = "performanceChroniclesGeneration",
}

export enum MaintenanceResourcesEnum {
  Maintenance = "maintenance",
}

export enum UserResourcesEnum {
  Users = "users",
}

export enum RegelleistungResourcesEnum {
  RegelleistungBids = "regelleistungBids",
}

export enum PlanningResourcesEnum {
  Plannings = "plannings",
  PlanningMasks = "planningMasks",
}

export enum RteCommunicationResourcesEnum {
  RteCommunicationTestConfig = "rteTestConfig",
  RteCommunicationAfrrTestConfig = "rteAfrrTestConfig",
  RteCommunicationGroups = "iecGroups",
  RteCommunicationFakePas = "fakePas",
  RteCommunicationConfigs = "configs",
  RteCommunicationPackets = "packets",
}

export enum RteCommunicationV2ResourcesEnum {
  RteCommunicationConfig = "rteCommunicationConfig",
  RteCommunicationFakePa = "rteCommunicationFakePa",
  RteCommunicationTestConfig = "rteCommunicationTestConfig",
  RteCommunicationMarketConfig = "rteCommunicationMarketConfig",
}

export enum TestsRteResourcesEnum {
  TestsRtePlannings = "testsRtePlannings",
  TestsAfrrRte = "testsAfrrRte",
  TestsFcrRte = "testsFcrRte",
}

export enum OptimizerResourcesEnum {
  OptiCertifications = "optiCertifications",
  OptiCertificationsTests = "optiCertificationsTests",
  OptiGroupsCertifications = "optiGroupsCertifications",
  OptiTasks = "optiTasks",
}

export enum AfrrEngagementResourcesEnum {
  Engagement = "engagement",
  Documents = "documents",
  GateDocument = "gateDocument",
  BidTimeSeries = "bidTimeSeries",
  Point = "point",
}

export enum TopNiveauResourcesEnum {
  TopNiveauBids = "topNiveauBids",
  TopNiveauGates = "topNiveauGates",
  TopNiveauDocuments = "topNiveauDocuments",
}

export enum EcpResourcesEnum {
  EcpBids = "ecpBids",
  EcpGates = "ecpGates",
  EcpReservePlanGates = "ecpReservePlanGates",
  EcpReservePlanPoints = "ecpReservePlanPoints",
}

export enum AirflowResourcesEnum {
  AirflowDags = "airflowDags",
}

export enum MecapaResourcesEnum {
  EmaxJGates = "emaxJGates",
  EmaxHGates = "emaxHGates",
  RtePowerGates = "rtePowerGates",
}

const {
  REACT_APP_AUTH_API_URL,
  REACT_APP_SITE_API_URL,
  REACT_APP_MAINTENANCE_API_URL,
  REACT_APP_REGELLEISTUNG_API_URL,
  REACT_APP_PLANNINGS_API_URL,
  REACT_APP_OPTIMIZER_API_URL,
  REACT_APP_IEC_API_URL,
  REACT_APP_AFRR_ENGAGEMENT_API_URL,
  REACT_APP_TOP_NIVEAU_API_URL,
  REACT_APP_ECP_API_URL,
  REACT_APP_AIRFLOW_SERVER,
  REACT_APP_MECAPA_API_URL,
} = process.env;

// Sites Resource Mapping
export const SiteResourceMapping: Record<SiteResourcesEnum | "root", string> = {
  root: REACT_APP_SITE_API_URL as string,
  [SiteResourcesEnum.Sites]: "sites",
  [SiteResourcesEnum.Statuses]: "sites/sites-statuses/",
  [SiteResourcesEnum.StatusesV2]: "sites/status_v2/",
  [SiteResourcesEnum.Groups]: "sites/groups/",
  [SiteResourcesEnum.Telemetry1S]: "sites/sites-telemetry/1s/",
  [SiteResourcesEnum.NLevels]: "sites/n_levels/",
  [SiteResourcesEnum.PerformanceChronicles]: "sites/performance_chronicles/",
  [SiteResourcesEnum.PerformanceChronicleResults]: "sites/performance_chronicle_results/",
  [SiteResourcesEnum.SiteConfigurations]: "sites/configurations/",
  [SiteResourcesEnum.PerformanceChronicleGeneration]: "",
};

// Maintenance Resource Mapping
export const MaintenanceResourceMapping: Record<MaintenanceResourcesEnum | "root", string> = {
  root: REACT_APP_MAINTENANCE_API_URL as string,
  [MaintenanceResourcesEnum.Maintenance]: "maintenance/maintenances",
};

// Users Resource Mapping
export const UserResourceMapping: Record<UserResourcesEnum | "root", string> = {
  root: REACT_APP_AUTH_API_URL as string,
  [UserResourcesEnum.Users]: "users",
};

// Regelleistung Resource Mapping
export const RegelleistungResourceMapping: Record<RegelleistungResourcesEnum | "root", string> = {
  root: REACT_APP_REGELLEISTUNG_API_URL as string,
  [RegelleistungResourcesEnum.RegelleistungBids]: "regelleistung/bids/",
};

// Plannings Resource Mapping
export const PlanningResourceMapping: Record<PlanningResourcesEnum | "root", string> = {
  root: REACT_APP_PLANNINGS_API_URL as string,
  [PlanningResourcesEnum.Plannings]: "plannings/",
  [PlanningResourcesEnum.PlanningMasks]: "plannings/planning-masks/",
};

// IEC Resource Mapping
export const RteCommunicationResourceMapping: Record<RteCommunicationResourcesEnum | "root", string> = {
  root: REACT_APP_IEC_API_URL as string,
  [RteCommunicationResourcesEnum.RteCommunicationTestConfig]: "iec/rte_test_configs/",
  [RteCommunicationResourcesEnum.RteCommunicationAfrrTestConfig]: "iec/rte_afrr_test_configs/",
  [RteCommunicationResourcesEnum.RteCommunicationGroups]: "iec/groups/",
  [RteCommunicationResourcesEnum.RteCommunicationFakePas]: "iec/fake-pas/",
  [RteCommunicationResourcesEnum.RteCommunicationConfigs]: "iec/configs/",
  [RteCommunicationResourcesEnum.RteCommunicationPackets]: "iec/iec104_packets/",
};

// RTE communication V2 Resource Mapping
export const RteCommunicationV2ResourceMapping: Record<RteCommunicationV2ResourcesEnum | "root", string> = {
  root: REACT_APP_IEC_API_URL as string,
  [RteCommunicationV2ResourcesEnum.RteCommunicationConfig]: "config/",
  [RteCommunicationV2ResourcesEnum.RteCommunicationFakePa]: "fake-pa/",
  [RteCommunicationV2ResourcesEnum.RteCommunicationTestConfig]: "test-config/",
  [RteCommunicationV2ResourcesEnum.RteCommunicationMarketConfig]: "/market-config/",
};

// Tests RTE Resource Mapping
export const TestsRteResourceMapping: Record<TestsRteResourcesEnum | "root", string> = {
  root: REACT_APP_IEC_API_URL as string,
  [TestsRteResourcesEnum.TestsRtePlannings]: "testsRte/plannings",
  [TestsRteResourcesEnum.TestsAfrrRte]: "",
  [TestsRteResourcesEnum.TestsFcrRte]: "",
};

// Optimizer Resource Mapping
export const OptimizerResourceMapping: Record<OptimizerResourcesEnum | "root", string> = {
  root: REACT_APP_OPTIMIZER_API_URL as string,
  [OptimizerResourcesEnum.OptiCertifications]: "certifications",
  [OptimizerResourcesEnum.OptiCertificationsTests]: "certifications_tests",
  [OptimizerResourcesEnum.OptiGroupsCertifications]: "groupscertifications",
  [OptimizerResourcesEnum.OptiTasks]: "opti_tasks",
};

// AFRR Engagement Resource Mapping
export const AfrrEngagementResourceMapping: Record<AfrrEngagementResourcesEnum | "root", string> = {
  root: REACT_APP_AFRR_ENGAGEMENT_API_URL as string,
  [AfrrEngagementResourcesEnum.Engagement]: "engagement/",
  [AfrrEngagementResourcesEnum.Documents]: "documents",
  [AfrrEngagementResourcesEnum.GateDocument]: "gate_document",
  [AfrrEngagementResourcesEnum.BidTimeSeries]: "bidtimeseries",
  [AfrrEngagementResourcesEnum.Point]: "point",
};

// Top Niveau Resource Mapping
export const TopNiveauResourceMapping: Record<TopNiveauResourcesEnum | "root", string> = {
  root: REACT_APP_TOP_NIVEAU_API_URL as string,
  [TopNiveauResourcesEnum.TopNiveauBids]: "bids",
  [TopNiveauResourcesEnum.TopNiveauGates]: "gates",
  [TopNiveauResourcesEnum.TopNiveauDocuments]: "documents",
};

// ECP Resource Mapping
export const EcpResourceMapping: Record<EcpResourcesEnum | "root", string> = {
  root: REACT_APP_ECP_API_URL as string,
  [EcpResourcesEnum.EcpBids]: "bids",
  [EcpResourcesEnum.EcpGates]: "gate",
  [EcpResourcesEnum.EcpReservePlanGates]: "reserve_plan_gate",
  [EcpResourcesEnum.EcpReservePlanPoints]: "reserve_plan_points",
};

// Airflow Resource Mapping
export const AirflowResourceMapping: Record<AirflowResourcesEnum | "root", string> = {
  root: REACT_APP_AIRFLOW_SERVER as string,
  [AirflowResourcesEnum.AirflowDags]: "api/v1/dags/chronique_anniversary_report_generation",
};

// Mecapa Resource Mapping
export const MecapaResourceMapping: Record<MecapaResourcesEnum | "root", string> = {
  root: REACT_APP_MECAPA_API_URL as string,
  [MecapaResourcesEnum.EmaxJGates]: "emax_j/",
  [MecapaResourcesEnum.EmaxHGates]: "emax_h/",
  [MecapaResourcesEnum.RtePowerGates]: "rte_power/",
};

export const dataProviderMappings = [
  SiteResourceMapping,
  MaintenanceResourceMapping,
  UserResourceMapping,
  RegelleistungResourceMapping,
  PlanningResourceMapping,
  RteCommunicationResourceMapping,
  RteCommunicationV2ResourceMapping,
  TestsRteResourceMapping,
  OptimizerResourceMapping,
  AfrrEngagementResourceMapping,
  TopNiveauResourceMapping,
  EcpResourceMapping,
  AirflowResourceMapping,
  MecapaResourceMapping,
];

const combineResources = (dataProviderMappings: Record<string, string>[]) => {
  // eslint-disable-next-line
  return dataProviderMappings.reduce((acc, { root, ...endpoints }) => {
    return { ...acc, ...endpoints };
  }, {});
};

export const ResourceMapping = combineResources(dataProviderMappings);
