import { Datagrid, DateField, List, ListActions, TextField } from "react-admin";
import { CreateDialog } from "@react-admin/ra-form-layout";
import RteCommunicationFakePaCreate from "./RteCommunicationFakePaCreate";

const RteCommunicationFakePaList = () => {
  return (
    <>
      <List actions={<ListActions hasCreate />}>
        <Datagrid>
          <TextField source="id" />
          <TextField source="r1_h" />
          <TextField source="r1_b" />
          <TextField source="r2_h" />
          <TextField source="r2_b" />
          <TextField source="p0" />
          <DateField showTime source="created_at" locales={"FR-fr"} />
          <DateField showTime source="updated_at" locales={"FR-fr"} />
        </Datagrid>
      </List>
      <CreateDialog fullWidth maxWidth="sm">
        <RteCommunicationFakePaCreate />
      </CreateDialog>
    </>
  );
};

export default RteCommunicationFakePaList;
